import React, { useEffect, useState } from 'react';
import { useCart } from '../../contexts/CartProvider'; // Atualize com o caminho correto para o seu CartContext
import styles from './style.module.scss';
import { BsCheckCircleFill } from 'react-icons/bs';

const CompraConcluida = ({ isFromSaldo = false }) => {
  const [idPagamento, setIdPagamento] = useState(null);
  const { total } = useCart();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('idPagamento');

    if (id) {
      setIdPagamento(id);
    }

    window.scrollTo(0, 0); // Rola a página para o topo

    // Lógica do dataLayer
    window.dataLayer = window.dataLayer || [];
    if (isFromSaldo) {
      window.dataLayer.push({
        'name_prod': 'Saldo',
        'event': 'purchase_saldo'
      });
    } else {
      window.dataLayer.push({
        'name_prod': 'Bolão',
        'event': 'purchase_bolao'
      });
    }

    // Facebook Pixel event tracking
    if (total) {
      window.fbq('track', 'Purchase', {
        value: total,
        currency: 'BRL',
      });
    }
  }, [total, isFromSaldo]); // total adicionado como dependência

  const formatarComoMoeda = (valor) => {
    if (!valor) return;

    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerPix}>
        <BsCheckCircleFill className={styles.icon} />
        <h1>Sua compra no valor de {total && formatarComoMoeda(total)} foi confirmada</h1>
        {idPagamento && <p>ID do Pagamento: {idPagamento}</p>}

        <p>Logo abaixo no resumo do pedido você pode acompanhar em tempo real a atualização do seu pagamento.</p>
      </div>
    </div>
  );
};

export default CompraConcluida;
