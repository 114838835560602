import React, { useState, useEffect } from 'react';
import { useCart } from '../../contexts/CartProvider'; // Atualize com o caminho correto para o seu CartContext
import styles from './style.module.scss';
import { Button, Input, Divider, Checkbox } from 'antd';
import { BsFillCreditCardFill } from 'react-icons/bs';
import { FaPix } from 'react-icons/fa6';

import logo from '../../assets/logo.png';
import CreditCard from '../CartaoGiratorio';
import { api } from '../../services/apiClient';
import { toast } from 'react-toastify';
import { useSaldo } from '../../contexts/SaldoContext';

const CartaoCredito = ({ items, handleNextStep, couponCode, setCouponCode, validarCupon }) => {
  const [isSaldoRoute, setIsSaldoRoute] = useState(false);
  const { clearCart, coupon, setTotal } = useCart();
  const { fetchSaldo } = useSaldo();

  useEffect(() => {
    setIsSaldoRoute(window.location.href.includes('/saldo'));
  }, []);

  function formatCurrency(value, locale = 'pt-BR', currency = 'BRL') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(value);
  }

  // Função para calcular os totais por grupo
  const totals = items.reduce((acc, item) => {
    if (!acc[item.nome]) acc[item.nome] = 0;
    acc[item.nome] += item.quantidade * item.valor;
    return acc;
  }, {});

  // Cálculo inicial do total geral
  const initialGrandTotal = Object.values(totals).reduce((acc, value) => acc + value, 0);
  const totalQuantidade = items.reduce((acc, item) => acc + item.quantidade, 0);

  // Verifica se o cupom é válido e aplica o desconto ao total geral
  let grandTotal = initialGrandTotal;
  let grandTotalBeforeDiscount = initialGrandTotal;
  let desconto = 0;
  if (coupon && coupon.isValid) {
    if (coupon.is_porcentagem === 1) {
      desconto = initialGrandTotal * (coupon.discount / 100);
      grandTotal -= desconto;
    } else {
      desconto = coupon.discount;
      grandTotal -= desconto;
    }
  }

  const [cardNumber, setCardNumber] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [cpf, setCpf] = useState('');
  const [parcelas, setParcelas] = useState('');

  const [loading, setLoading] = useState(false);

  const handlePayCard = async () => {
    setLoading(true);
    if (!cardNumber || !cardHolderName || !expiryDate || !cvv || !cpf || !parcelas) {
      toast.warn('Por favor Preencha todos os dados do cartão', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setLoading(false);
      return;
    }
    if (!aceiteTermos) {
      toast.warn('Por favor aceite os termos de uso e a política de privacidade', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setLoading(false);
      return;
    }

    const dataTokenCard = JSON.stringify({
      card_number: cardNumber.replace(/\D/g, ''),
      cardholder: {
        name: cardHolderName,
        identification: {
          type: 'CPF',
          number: cpf.replace(/\D/g, ''),
        },
      },
      security_code: cvv,
      expiration_month: expiryDate.split('/')[0],
      expiration_year: `20${expiryDate.split('/')[1]}`,
    });

    const requestOptions = {
      method: 'POST',
      body: dataTokenCard,
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    };

    try {
      const response = await fetch('https://api.mercadopago.com/v1/card_tokens?public_key=APP_USR-c69facd8-08eb-4dfc-b299-f86744ee816a', requestOptions);
      const resultToken = await response.json();

      const responseIssuerId = await fetch(`https://api.mercadopago.com/v1/payment_methods/search?public_key=APP_USR-c69facd8-08eb-4dfc-b299-f86744ee816a&locale=pt-BR&bins=${resultToken.first_six_digits}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
      });

      const result = await responseIssuerId.json();

      if (result.results && result.results.length > 0) {
        const issuerId = result.results[0].issuer.id;
        const paymentMethodId = result.results[0].id;

        if (isSaldoRoute) {
          try {
            const responsePayment = await api.post('/carrega_saldo', {
              cpfCnpjCartaoCredito: cpf.replace(/\D/g, ''),
              nomeTitularCartao: cardHolderName,
              quantidadeParcelasPagamento: parcelas,
              tipoDocumento: 'CPF',
              tokenCard: resultToken.id,
              issuerId: issuerId,
              paymentMethodId: paymentMethodId,
              total: grandTotal,
            });
            setTotal(grandTotal);

            if (responsePayment.status === 201) {
              setLoading(false);
              toast.success('Compra feita com sucesso', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              fetchSaldo();
              handleNextStep(responsePayment.data.id);
              clearCart();
            }
          } catch (e) {
            setLoading(false);
            console.log('e', e.message);
          }
        } else {
          try {
            const responsePayment = await api.post('/comprar', {
              cupom_desconto_id: coupon['id'],

              boloes: items,
              quantity: totalQuantidade,
              cpfCnpjCartaoCredito: cpf.replace(/\D/g, ''),
              nomeTitularCartao: cardHolderName,
              quantidadeParcelasPagamento: parcelas,
              total: grandTotalBeforeDiscount,
              tokenCard: resultToken.id,
              issuerId: issuerId,
              paymentMethodId: paymentMethodId,
            });
            console.log('responsePayment', responsePayment);
            if (responsePayment.status === 201) {
              setLoading(false);
              if (responsePayment.data.status === 'rejected') {
                toast.error(' Falha ao realizar a compra. Verifique se os dados do cartão estão corretos.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
                // if (responsePayment.data.status_detail === 'cc_rejected_bad_filled_security_code') {
                // } else {
                //   // toast.error('Falha ao realizar a compra, verifique o cartão', {
                //   //   position: 'top-right',
                //   //   autoClose: 5000,
                //   //   hideProgressBar: false,
                //   //   closeOnClick: true,
                //   //   pauseOnHover: true,
                //   //   draggable: true,
                //   //   progress: undefined,
                //   //   theme: 'light',
                //   // });
                // }
                return;
              } else if (responsePayment.data.status === 'in_process') {
                // toast.error('Falha ao realizar a compra, verifique o cartão', {
                //   position: 'top-right',
                //   autoClose: 5000,
                //   hideProgressBar: false,
                //   closeOnClick: true,
                //   pauseOnHover: true,
                //   draggable: true,
                //   progress: undefined,
                //   theme: 'light',
                // });
              } else {
                toast.success('Compra feita com sucesso', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
                clearCart();
                window.scrollTo(0, 0);
                fetchSaldo();

                handleNextStep(responsePayment.data.id);
                return;
              }
            }
          } catch (e) {
            setLoading(false);
            // toast.error('Falha ao realizar a compra, verifique o cartão', {
            //   position: 'top-right',
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: 'light',
            // });
            console.log('e', e.message);
          }
        }
      } else {
        setLoading(false);
        // toast.error('Erro ao obter dados do método de pagamento', {
        //   position: 'top-right',
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: 'dark',
        // });
      }
    } catch (error) {
      setLoading(false);
      console.log('Erro ao processar pagamento', error);
      toast.error('Erro ao processar pagamento', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
    // toast.error('Erro ao obter dados do método de pagamento')
  };

  const [cupon, setCupom] = useState('');
  const [cupomAplicado, setCupomAplicado] = useState(false);

  const [aceiteTermos, setAceiteTermos] = useState(false);

  const handleCupon = async () => {
    const validar = await validarCupon(cupon);
    console.log('validar', validar);
    if (validar) {
      setCupomAplicado(true);
      return;
    }
  };

  return (
    <>
      <div className={styles.container}>
        {!isSaldoRoute && (
          <div className={styles.containerCupom}>
            <h1>Você tem algum cupom de desconto?</h1>
            <div>
              <Input className={styles.containerInput} onChange={(e) => setCouponCode(e.target.value)} disabled={cupomAplicado} />
              <Button className={styles.button} onClick={handleCupon} disabled={cupomAplicado}>
                Aplicar Cupom
              </Button>
            </div>
          </div>
        )}
        <br />
        <CreditCard
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
          cardHolderName={cardHolderName}
          setCardHolderName={setCardHolderName}
          expiryDate={expiryDate}
          setExpiryDate={setExpiryDate}
          cvv={cvv}
          setCvv={setCvv}
          cpf={cpf}
          setCpf={setCpf}
          parcelas={parcelas}
          setParcelas={setParcelas}
        />

        <Checkbox onChange={(e) => setAceiteTermos(e.target.checked)}>
          Li e concordo com os &nbsp;
          <strong>Termos de Uso</strong>
        </Checkbox>

        <div className={styles.totalContainer}>
          <h1>Resumo do pedido</h1>
          {Object.keys(totals).map((nome) => (
            <div key={nome} className={styles.containerTotalValorNomes}>
              <h2>{nome}:</h2>
              <p>{formatCurrency(totals[nome])}</p>
            </div>
          ))}
          <div className={styles.containerTotalValorNomes}>
            {coupon && coupon.isValid && (
              <>
                <h2 style={{ color: 'green' }}>CUPOM: </h2>
                <p style={{ color: 'green' }}>
                  {couponCode} -{formatCurrency(desconto)}
                </p>
              </>
            )}
          </div>
          <Divider style={{ width: '100%', borderColor: '#000' }} />

          <div className={styles.valorTotal}>
            <h3>Valor Total:</h3>
            <h5>{formatCurrency(grandTotal)}</h5>
          </div>
          <Button loading={loading} disabled={loading} className={styles.containerButtonProximo} onClick={handlePayCard}>
            Confirmar compra
          </Button>
        </div>
        <p className={styles.aviso}>Após finalizar a compra não é possivel estorná-la</p>
      </div>
    </>
  );
};

export default CartaoCredito;
