import React, { createContext, useContext, useEffect, useState } from 'react';
import { api } from '../services/apiClient'; // Importa sua API client
import { toast } from 'react-toastify';

export const CartContext = createContext();

export const useCart = () => useContext(CartContext);

const cartLocalStorageKey = 'cartItems';
const couponLocalStorageKey = 'cartCoupon';

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const localData = localStorage.getItem(cartLocalStorageKey);
    return localData ? JSON.parse(localData) : [];
  });

  const [coupon, setCoupon] = useState(() => {
    const localData = localStorage.getItem(couponLocalStorageKey);
    return localData ? JSON.parse(localData) : { code: '', isValid: false, discount: 0 };
  });
  const [total, setTotal] = useState();

  useEffect(() => {
    localStorage.setItem(cartLocalStorageKey, JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    localStorage.setItem(couponLocalStorageKey, JSON.stringify(coupon));
  }, [coupon]);

  const validateCoupon = async (code) => {
    console.log('code', code);
    try {
      const response = await api.post('/cupom_existe', { codigo: code, bolao_carrinho: cartItems[0]?.grupo });
      console.log('response.data', response.data);
      if (response.data.cupons) {
        setCoupon({ code, isValid: true, id: response.data.cupons.id, discount: response.data.cupons.valor, is_porcentagem: response.data.cupons.is_porcentagem });
        return true;
      } else {
        setCoupon({ code, isValid: false, discount: 0 });
        toast.error('Cupom inválido');
        return false;
      }
    } catch (error) {
      console.error('Erro ao validar cupom:', error);
    }
  };

  const addToCart = (newItem) => {
    clearCoupon();
    setCartItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex((item) => item.id === newItem.id);
      if (existingItemIndex > -1) {
        const updatedItems = [...prevItems];
        updatedItems[existingItemIndex].quantidade = (updatedItems[existingItemIndex].quantidade || 1) + newItem.quantidade;
        updatedItems[existingItemIndex].numero_cotas = updatedItems[existingItemIndex].quantidade + newItem.quantidade; // Atualiza numero_cotas
        return updatedItems;
      } else {
        return [...prevItems, { ...newItem, numero_cotas: newItem.quantidade, addedAt: Date.now() }];
      }
    });
  };

  const removeFromCart = (itemId) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const removeItemFromCart = (itemId, quantity) => {
    setCartItems((prevItems) => {
      const itemIndex = prevItems.findIndex((item) => item.id === itemId);
      if (itemIndex !== -1) {
        const updatedItems = [...prevItems];
        updatedItems[itemIndex].quantidade = (updatedItems[itemIndex].quantidade || 0) - quantity;
        updatedItems[itemIndex].numero_cotas = updatedItems[itemIndex].quantidade; // Atualiza numero_cotas
        if (updatedItems[itemIndex].quantidade <= 0) {
          updatedItems.splice(itemIndex, 1);
        }
        return updatedItems;
      } else {
        return prevItems;
      }
    });
  };

  const clearCart = () => {
    setCartItems([]); // Limpa todos os itens do carrinho
  };

  const clearCoupon = () => {
    setCoupon({ code: '', isValid: false, discount: 0 }); // Reseta o cupom
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const updatedCartItems = cartItems.filter((item) => now - item.addedAt < 15 * 60 * 1000);
      if (updatedCartItems.length !== cartItems.length) {
        setCartItems(updatedCartItems);
      }
    }, 1000 * 60); // Verifique a cada minuto

    return () => clearInterval(interval);
  }, [cartItems]);

  const value = {
    items: cartItems,
    addToCart,
    removeFromCart,
    removeItemFromCart, // Adicione esta função ao contexto
    clearCart,
    clearCoupon,
    coupon,
    validateCoupon,
    total,
    setTotal,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
